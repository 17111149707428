import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, AUTH_FEATURE_KEY } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>(
  AUTH_FEATURE_KEY,
);

export const isLoggedIn = createSelector(
  selectAuthState,
  (state: AuthState) => state?.loggedIn,
);

export const loggedInUser = createSelector(
  selectAuthState,
  (state: AuthState) => state?.me,
);

export const token = createSelector(
  selectAuthState,
  (state: AuthState) => state?.token || false,
);

export const loginFailed = createSelector(
  selectAuthState,
  (state: AuthState) => state?.loginErrorMessage || false,
);

export const registerFailed = createSelector(
  selectAuthState,
  (state: AuthState) => state?.registerErrorMessage || false,
);

export const registrationStep = createSelector(
  selectAuthState,
  (state: AuthState) => state?.registrationStep,
);

export const activeLanguage = createSelector(
  selectAuthState,
  (state: AuthState) => state?.me?.language || 'en',
);
