import { createAction, props } from '@ngrx/store';
import { User } from '../../authapi.types';

const _ = log => `[Auth] ${log}`;

export const restoreLastSession = createAction(_('Restore last session'));
export const restoreLastSessionSuccess = createAction(_('Restore last session success'), props<{ loggedIn: boolean; token: string }>());

export const setSession = createAction(_('Set session data'), props<{ token: string; user: User }>());

export const logIn = createAction(_('Log in'), props<{ username: string; password: string }>());
export const logInSuccess = createAction(_('Log in success'), props<{ token: string; user: User }>());
export const logInFailed = createAction(_('Log in failed'), props<{ message: string }>());
export const logInFailedClear = createAction(_('Log in failed clear'));

export const register = createAction(_('Register'), props<{ email: string; password: string }>());
export const registerSuccess = createAction(_('Register success'), props<{ token: string; user: User }>());
export const registerFailed = createAction(_('Register failed'), props<{ message: string }>());
export const registerFailedClear = createAction(_('Register failed clear'));

export const verifyToken = createAction(_('Verify token'), props<{ token: string }>());
export const verifyTokenSuccess = createAction(_('Verify token success'));
export const verifyTokenFailed = createAction(_('Verify token failed'), props<{ message: string }>());

export const signOut = createAction(_('Sign out'));
export const signOutSuccess = createAction(_('Sign out successful'));

export const tokenInvalid = createAction(_('Token invalid'));
export const tokenExpired = createAction(_('Token expired'));
