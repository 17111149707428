import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { authReducer } from './state/auth/auth.reducer';
import { AuthEffects } from './state/auth/auth.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthCommonModule } from './common/common.module';
import { AuthRoutingModule } from './auth-routing.module';

export interface AuthModuleConfig {
  loginPath: string;
  // Other configuration properties
}

export const AUTH_MODULE_CONFIG = new InjectionToken<AuthModuleConfig>('AuthModuleConfig');

const DEFAULT_CONFIG: AuthModuleConfig = {
  loginPath: 'login',
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthCommonModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ]
})
export class AuthModule {
  static forRoot(config: AuthModuleConfig): ModuleWithProviders<AuthModule> {
    const mergedConfig = { ...DEFAULT_CONFIG, ...config };

    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_MODULE_CONFIG,
          useValue: mergedConfig
        }
      ]
    };
  }
}
