import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, tap } from 'rxjs';
import { isLoggedIn } from './state/auth/auth.selectors';

export const AuthGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.pipe(
    select(isLoggedIn),
    // Check if loggedIn is boolean
    filter((loggedIn) => typeof loggedIn === 'boolean'),
    tap((loggedIn) => {
      console.log('Auth guard loggedIn', loggedIn);
      if (loggedIn === false) {
        router.navigate(['/auth/login']);
      }
    })
  );
};
