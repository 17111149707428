import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { activeLanguage } from './auth/state/auth/auth.selectors';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  stage = environment.stage;

  constructor(private store: Store, private translate: TranslateService) {
    translate.setDefaultLang('en');

    this.store.pipe(
      select(activeLanguage),
    ).subscribe(async (language) => {
      let translation;

      if (language === 'tr') {
        translation = await import(`./translations/tr`);
      }
      if (language === 'nl') {
        translation = await import(`./translations/nl`);
      }
      if (language === 'en') {
        translation = await import(`./translations/en`);
      }

      translate.setTranslation(language, translation.default);
      translate.use(language);
    });
  }
}
