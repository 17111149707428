import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { addDays, formatISO, parseISO } from 'date-fns';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { logIn, logInFailed, logInSuccess, register, registerFailed, registerSuccess, restoreLastSession, setSession, signOut, signOutSuccess, tokenExpired, tokenInvalid, verifyToken, verifyTokenFailed, verifyTokenSuccess } from './auth.actions';
import { ApiService } from '../../authapi.service';
import { showToast } from '../toast/toast.actions';
import Logger from '../../logger.service';
import { environment } from 'src/environments/environment';
const log = Logger('effects:auth');

export const LS_TOKEN_KEY = 'auth';
export const LS_TOKEN_EXPIRE_KEY = 'auth_expire';

@Injectable()
export class AuthEffects {
  restoreLastSession$ = createEffect(() => this.actions$.pipe(
    ofType(restoreLastSession),
    switchMap(() => {
      log('restoreLastSession');
      if (localStorage.getItem(LS_TOKEN_KEY)) {
        const token = localStorage.getItem(LS_TOKEN_KEY);

        this.api.setToken(token);

        return this.api.me().pipe(
          tap((user) => log('restored user', user)),
          // tap((user) => {
          //   if (window.simplebase && window.simplebase.updateUserAttributes) {
          //     window.simplebase.updateUserAttributes({
          //       email: user.email,
          //       id: user.id,
          //     })
          //   } else {
          //     log('simplebase is not ready yet.');
          //   }
          // }),
          map((user) => setSession({ token, user })),
          tap(() => log('session set')),
          catchError((error) => {
            console.error(error);
            return of(tokenInvalid());
          })
        );
      }
      log('Nothing to restore;')
      return of(tokenInvalid());
    }),
  ));

  logIn$ = createEffect(() => this.actions$.pipe(
    ofType(logIn),
    mergeMap(({ username, password }) => this.api.login(username, password)
      .pipe(
        map(({ user, token }) => logInSuccess({ token, user })),
        catchError((error) => {
          log('login failed', error);
          return of(logInFailed({ message: 'Login failed. Please check your e-mail address and password.' }))
        })
      )
    ),
  ));

  saveToken$ = createEffect(() => this.actions$.pipe(
    ofType(logInSuccess, registerSuccess),
    tap(({ token }) => {
      localStorage.setItem(LS_TOKEN_KEY, token);
      this.api.setToken(token);
    }),
    map(({ user, token }) => setSession({ token, user })),
  ));


  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(logInSuccess),
    tap(({ }) => {
      location.href = '/';
    }),
  ), { dispatch: false });

  tokenExpiredMessage$ = createEffect(() => this.actions$.pipe(
    ofType(tokenExpired),
    map(() => showToast({ message: 'Your session has expired. Please log in again.' })),
  ));

  cleanupToken$ = createEffect(() => this.actions$.pipe(
    ofType(tokenExpired, tokenInvalid, signOut),
    tap(() => {
      localStorage.removeItem(LS_TOKEN_KEY);
      this.api.setToken(null);
    }),
    map(() => signOutSuccess())
  ));

  signOut$ = createEffect(() => this.actions$.pipe(
    ofType(signOut),
    tap(() => {
      log('sign out');
      this.router.navigate(['/login']);
    }),
    map(() => showToast({ message: 'You signed out successfully!' })),
  ));

  // signOutSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(signOutSuccess),
  //   tap(() => {
  //     log('sign out success');
  //     // this.router.navigate(['/login']);
  //   })
  // ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private router: Router
  ) { }

  ngrxOnInitEffects() {
    return restoreLastSession();
  }

}

declare global {
  interface Window {
    simplebase: {
      updateUserAttributes: (attributes: any) => void;
    };
  }
}
