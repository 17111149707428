import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalViewComponent } from './modal-view/modal-view.component';
import { IonicModule } from '@ionic/angular';
import { AddSiteComponent } from './add-site/add-site.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [ModalViewComponent, AddSiteComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ModalViewComponent, AddSiteComponent]
})
export class AuthCommonModule { }
