import { createReducer, on } from '@ngrx/store';
import { productsFetched } from './project.actions';

export const PROJECT_FEATURE_KEY = 'project';

export interface ProjectState {
  products: [];
}

export const initialState: ProjectState = {
  products: []
};

export const projectReducer = createReducer(
  initialState,
  on(productsFetched, (state, { products }) => ({
    ...state,
    products
  })),
);
