import { createReducer, on } from '@ngrx/store';
import {
  logInFailed,
  logInFailedClear,
  logInSuccess,
  registerFailed,
  registerFailedClear,
  setSession,
  signOutSuccess
} from './auth.actions';
import { User } from '../../authapi.types';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  loggedIn: boolean | null;
  token: string;
  loginErrorMessage: string;
  registrationStep: 'start' | 'verify' | 'success';
  registerErrorMessage: string;
  me: User;
}

export const initialState: AuthState = {
  loggedIn: null,
  token: '',
  loginErrorMessage: '',
  registrationStep: 'start',
  registerErrorMessage: '',
  me: null,
};

export const authReducer = createReducer(
  initialState,
  on(setSession, (state, { token, user }) => ({
    ...state,
    loggedIn: true,
    token,
    me: user
  })),

  on(signOutSuccess, (state) => ({
    ...state,
    loggedIn: false,
    token: '',
  })),

  on(logInSuccess, (state, { token }) => ({
    ...state,
    loggedIn: true,
    token,
  })),

  on(logInFailed, (state, { message }) => ({
    ...state,
    loginErrorMessage: message
  })),

  on(logInFailedClear, ((state) => ({
    ...state,
    loginErrorMessage: ''
  }))),

  on(registerFailed, (state, { message }) => ({
    ...state,
    registerErrorMessage: message
  })),

  on(registerFailedClear, ((state) => ({
    ...state,
    registerErrorMessage: ''
  }))),
);
