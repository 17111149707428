import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const loginPath = 'login';
export const registerPath = 'register';
export const emailConfirmedPath = 'email-confirmed';
export const resetPasswordPath = 'reset-password';

const routes: Routes = [
  {
    path: loginPath,
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: registerPath,
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: emailConfirmedPath,
    loadChildren: () => import('./email-confirmed/email-confirmed.module').then( m => m.EmailConfirmedPageModule)
  },
  {
    path: resetPasswordPath,
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
