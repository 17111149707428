import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  AuthResponse,
  User
} from './authapi.types';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.apiUrl;
  private token = '';

  constructor(private http: HttpClient) {
  }

  setToken(token: string) {
    this.token = token;
  }

  me(): Observable<User> {
    return this.http.get(`${this.baseUrl}/users/me`, {
      headers: {
        authorization: `Bearer ${this.token}`
      }
    }).pipe(
      map((res) => res as User)
    );
  }

  register(email, password) {
    console.log(email, password);
    return this.http.post<AuthResponse>(`${this.baseUrl}/users`, {
      email,
      password,
    }).pipe(
      tap((resp) => { console.log(resp); }),
    );
  }

  verifyToken(code) {
    return this.http.put(`${this.baseUrl}/users/me/verify`, {
      code,
    }, {
      headers: {
        authorization: `Bearer ${this.token}`,
      }
    });
  }

  resendToken() {
    return this.http.post(`${this.baseUrl}/users/me/verify`, {}, {
      headers: {
        authorization: `Bearer ${this.token}`,
      }
    });
  }

  /**
   * Password reset
   *
   * @param email Email address to send password reset
   */
  passwordReset(email) {
    return this.http.delete(`${this.baseUrl}/users/me/password`, {
      params: {
        email,
      },
    });
  }

  updatePassword(token, password) {
    return this.http.put(`${this.baseUrl}/users/me/password`, {
      password,
    }, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  login(email, password) {
    return this.http
      .post<AuthResponse>(`${this.baseUrl}/sessions`, {
        email,
        password,
      })
      .pipe(
        tap((resp) => {
          if (resp.token) {
            this.setToken(resp.token);
          }
        })
      );
  }
}
